html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
} */

@font-face {
  font-family: 'FuturaMaxiCGBold';
  src: url('/fonts/FuturaMaxiCGBold.otf');
}
@font-face {
  font-family: 'OpenSauceTwo-Bold';
  src: url('/fonts/OpenSauceTwo-Bold.ttf');
}
@font-face {
  font-family: 'OpenSauceTwo-Regular';
  src: url('/fonts/OpenSauceTwo-Regular.ttf');
}
@font-face {
  font-family: 'OpenSauceTwo-SemiBold';
  src: url('/fonts/OpenSauceTwo-SemiBold.ttf');
}

.container {
  width: 100%;
}
.display-mobile {
  display: block;
}
.display-desktop {
  display: none;
}
.date-form-control {
  font-size: 16px;
  z-index: 5;
}
@media (min-width: 576px) {
  .container {
    width: 576px;
  }
  .display-mobile {
    display: block;
  }
  .display-desktop {
    display: none;
  }
}
@media (min-width: 1024px) {
  .container {
    width: 1024px;
  }
  .display-mobile {
    display: none;
  }
  .display-desktop {
    display: block;
  }
  .date-form-control {
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 992px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}

.pac-container {
  z-index: 10000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.react-datepicker__navigation--years::before {
  border-color: #666;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  left: 11px;
  position: absolute;
  width: 9px;
}

.react-datepicker__navigation--years-upcoming::before {
  top: 17px;
  transform: rotate(315deg);
}

.react-datepicker__navigation--years-previous::before {
  top: 6px;
  transform: rotate(135deg);
}
